import useAxiosPrivate from '../Hooks/useAxiosPrivate';
import {useEffect, useState} from 'react';
import { Link} from 'react-router-dom';
import useAuth from '../Hooks/useAuth';
import {jwtDecode} from 'jwt-decode';

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import PagesBanner from './PagesBanner';

const UPLOAD_FILE_URL = '/singleUpload';
const DELETE_FILE_URL = '/fileDelete/';
const VIEW_PAGE_URL = '/pages/view/';
const EDIT_PAGE_URL = '/pages/edit/';

function Page({id, img}) {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    const [selectedImages, setSelectedImages] = useState([]); 
    const [updateMode, setUpdateMode] = useState(false);
    const [pageText, setPageText] = useState('');
    const [pictures, setPictures] = useState([]);
    const [initialPics, setInitialPics] = useState([]);
    const [pageData, setPageData] = useState({});
    const [title, setTitle] = useState('');
    const [page, setPage] = useState({});
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_PAGE_URL + id).then((response)=>{
            if(isMounted){
                setPageData(response.data);
                setPage(response.data.page);
                setTitle(response.data.page.title);
                setPageText(response.data.page.pageText);
                setPictures(response.data.page.picture.split(','));
                setInitialPics(response.data.page.picture.split(','));
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line   
    }, [id]);

    const onPageText = (value) => { setPageText(value); }

    function deletePictures(pic) {
        setPictures(pictures.filter((e) => e !== pic));
    }
    
    const onSelectFile = (e) => {
        const selectedFiles = e.target.files;    
        const selectedFilesArray = Array.from(selectedFiles);
        
        const imagesFiltered = [];
    
        for(let i=0; i<selectedFilesArray.length; i++){
          if(selectedFilesArray[i].name.match(/\.(JPG|jpg|jpeg|JPEG|png|PNG)$/) && selectedFilesArray[i].size < 700000){
            imagesFiltered.push(selectedFilesArray[i]);
          }
        }

        const imagesArray = imagesFiltered.map((file) => {
          return ( {"image": URL.createObjectURL(file), "file": file } )
        });
        
        setSelectedImages((previousImages) => previousImages.concat(imagesArray));
        
        // FOR BUG IN CHROME
        e.target.value = "";
    };
    
    function deleteHandler(image) {
        setSelectedImages(selectedImages.filter((e) => e !== image));   
        URL.revokeObjectURL(image.image);
    }

    const updatePage = async(e) => {
        e.preventDefault();
        const pageId = id;  
        let picture = '';
        
        if(pictures.length>0){
            picture = pictures[0];
            for(let i=1; i<pictures.length; i++) {
                picture = picture + ',' + pictures[i];
            }
        }
        
        if(selectedImages.length>0 && pictures.length<3){
            let numberOfnewPics = 3 - pictures.length;
            
            for(let i=0; i<numberOfnewPics && i<selectedImages.length; i++) {
                const formData = new FormData();
                const filename = Date.now()+ ' - ' + selectedImages[i].file.name;       
                formData.append("name", filename);
                formData.append("file", selectedImages[i].file);
                
                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);   
                }catch (error) {
                    console.log(error);  
                }
                
                if(i===0){
                    picture = filename;
                }else {
                    picture = picture + ',' + filename;
                }
            }
        }
        
        if(!pictures && !selectedImages){
            picture = pageData.page.picture;
        }

        for(let i=0; i<initialPics.length; i++){
            if(!picture.includes(initialPics[i])){
                axiosPrivate.post(DELETE_FILE_URL + initialPics[i]).then(()=>{
                    console.log('Old image deleted');
                }).catch(function(error){
                    console.log(error.config);
                });
            }
        }

        const updatedPage = {title, picture, pageText, id: page.id};
        
        try {
            axiosPrivate.put(EDIT_PAGE_URL + pageId, updatedPage).then(()=>{
                alert('Page Edited');
                setUpdateMode (false);
                window.location.reload();
            }).catch(function(error){
                console.log(error.config);
            });   
        }catch (error) {
            console.log(error);  
        }
    }

    return (
        <>
            <PagesBanner title={page.title} img={img} />
            <section id="about-page" className="pb-30 gray-bg">
                <div className="container">
                    { updateMode ? (
                        <form className="p-2">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="section-title form-group">
                                        <br /><h5>Title:</h5> <input className="form-control p-2" required="required" type='text' value={title} onChange={(e)=>setTitle(e.target.value)} />
                                    </div>
                                    <div className="section-title form-group">
                                        <br /><h5>Page Details:</h5>
                                        <ReactQuill theme="snow" rows="10" value={pageText} onChange={onPageText} />
                                    </div>
                                </div>
                                <div className="form-group col-lg-5">
                                    <div className="section-title form-group">
                                        <br /><h5>Pictures:</h5>
                                    </div>                            
                                    { pictures.map((pic, idx) =>{
                                        return(
                                            <div key={idx}>
                                                <img src={img + pic} alt={pic} className="img-fluid w-100" />
                                                <i className="fa fa-trash" aria-hidden="true" onClick={() => deletePictures(pic)}/>
                                                <br /><br />
                                            </div>
                                        )
                                    })}

                                    { selectedImages.length > 0 && selectedImages.map((image) => { 
                                        return (
                                            <div key={image.image}>
                                                <img src={image.image} alt="upload" className="img-fluid w-100" /><br />
                                                <i className="fa fa-trash" aria-hidden="true" onClick={() => deleteHandler(image)}/>
                                            </div>
                                        );
                                    })}
                                    <label htmlFor="fileInput">
                                        <h5><i className="fa fa-recycle"></i> Change Pictures</h5>
                                        <input id="fileInput" type="file" name="files" style={{display:'none'}} onChange={(e) => onSelectFile(e)} multiple accept="image/png, image/jpeg, image/jpg" />
                                    </label>
                                    <br/><br/>
                                    <p><i className='fa fa-warning' style={{color: 'red', fontSize: '1.5rem'}}/>&nbsp;&nbsp;Only jpg, jpeg and png files of not more than 700kb will be uploaded</p>
                                    <br/><br/>
                                </div>
                            </div>
                            <br />
                            <button type="submit" className="main-btn" onClick={updatePage}>Update</button>
                            &nbsp;&nbsp;&nbsp;  
                            <Link className="main-btn" onClick={(e)=>setUpdateMode(false)} to={`/${id}`}>Cancel</Link> 
                        </form> 
                    ) : (
                        <div className='container-fluid'>
                            <div className="section-title pt-3">
                                {(authDecode && authDecode.user.role >= 3) && 
                                    <span className='about-cont'>
                                        <Link to='#update'><i className="fa fa-edit" aria-hidden="true" onClick={() => setUpdateMode(true)}/> </Link>
                                        &nbsp;Edit
                                    </span>
                                }
                            </div> { /*<!-- section title --> */}
                            <div className='about-cont'>
                                { pictures.length>0 && <img className="pages-image-left pb-3 pt-5" src={img + pictures[0]} alt={page.tag} style={{height: '500px', objectFit: 'cover'}}/> }
                                <p dangerouslySetInnerHTML={{ __html: pageData.textBatch1}} style={{whiteSpace: "pre-line"}} />
                            </div>
                            {page.tag==='about' && 
                                <div className="row ml-50 mr-50">
                                    <div className="col-lg-6 col-md-6 col-sm-10">
                                        <div className="about-singel-items mt-30 mb-30">
                                            <span>Our Vision</span>
                                            <p>To be an inclusive school that has high aspirations for every child, to create a well facilitated institution that is capable of shaping children into future responsible leaders</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-10">
                                        <div className="about-singel-items mt-30 mb-30">
                                            <span>Our Mission</span>
                                            <p>To give learners the best possible head start on their academic careers by encouraging their natural inquisitiveness, creativity and motivation to learn.</p>
                                        </div>
                                    </div>
                                </div>
                            }
                            { pageData.textBatch2 &&
                                <div className='about-cont'> 
                                    { pictures.length>1 && <div className='pt-5'><img src={img + pictures[1]} alt={page.tag} className="img-fluid w-100" style={{height: '400px', objectFit: 'cover'}}/></div> }
                                    <p className="justify-content-center" dangerouslySetInnerHTML={{ __html: pageData.textBatch2}} style={{whiteSpace: "pre-line"}} />
                                </div>
                            }          
                            { pageData.textBatch3 && 
                                <div className='about-cont'>
                                    { pictures.length>2 && <div className='pt-5'><img src={img + pictures[2]} alt={page.tag} className="img-fluid w-100" style={{height: '400px', objectFit: 'cover'}}/></div>}
                                    <p dangerouslySetInnerHTML={{ __html: pageData.textBatch3}} style={{whiteSpace: "pre-line"}} />
                                </div>
                            }           
                        </div>
                    )}
                </div> { /*<!-- container --> */}
            </section> 
        </>
    )
}

export default Page