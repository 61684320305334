// import FrontPageModal from "../Components/FrontPageModal";
import Newshighlights from "./Home/Newshighlights";
import Facilities from "./Home/Facilities";
import Teachers from "./Home/Teachers";
import About from "./Home/About";
import Hero from "./Home/Hero";
import Events from "./Home/Events";

function Home({img}) {  
    return (
      <div className=" gray-bg">
        {/* <FrontPageModal />  */}
        <div className="search-box">
          <div className="serach-form">
            <div className="closebtn">
              <span />
              <span />
            </div>
            <form action="#">
              <input type="text" placeholder="Search by keyword" />
              <button><i className="fa fa-search" /></button>
            </form>
          </div> 
        </div>
        <Hero img={img}/>
        <About img={img}/>
        <Facilities img={img}/>
        <Newshighlights img={img}/>
        <Events img={img}/>
        <Teachers img={img}/>
      </div>
    )
}

export default Home

