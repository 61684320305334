import AlumniFeedback from '../Pages/AlumniFeedback';
import { Route, Routes } from 'react-router-dom';
import Articles from './Articles';
import Gallery from './Gallery';
import Events from './Events';
import News from './News';

function Alumni({img}) {
    const cat = 1;
    
    return (
        <Routes>
            <Route exact path='/algallery/*' element={<Gallery img={img} cat={cat}/>} />
            <Route exact path='/alblog/*' element={<Articles img={img} cat={cat}/>} />
            <Route exact path='/alevents/*' element={<Events img={img} cat={cat}/>} />
            <Route exact path='/alnews/*' element={<News img={img} cat={cat}/>} />
            <Route exact path='/alumcontact/*' element={<AlumniFeedback img={img}/>} />
        </Routes>
    )
}

export default Alumni