import React from 'react';
import {Link} from 'react-router-dom';

function MessageSent ({img}) {
    return (
      <>
        <section id="page-banner" className="pt-105 pb-80 bg_cover" data-overlay="8"  style={{backgroundImage: `url(${img}contactBanner.jpg)`}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-banner-cont">
                  <h2>Message Sent</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Message Sent</li>
                    </ol>
                  </nav>
                </div>  { /* { /*<!-- page banner cont --> */} 
              </div>
            </div> { /* { /*<!-- row --> */} 
          </div> { /* { /*<!-- container --> */}
        </section>
        <section id="teachers-part" className="pt-90 pb-40 gray-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className='contact-from mt-30'>
                    <div className="section-title">
                        <h5>Message Sent</h5>
                        <h2>Message Sent!</h2>
                    </div> { /* <!-- section title --> */ }
                    <div className='blog-details pt-45 mb-65'>
                      <p>
                        Thank you for getting in touch with us. Your message has been sent successfully. Our team will reach out to follow up on the message sent. 
                        <br /><br />
                        <Link to='/'>Return to the Home Page</Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="happy-student mt-30">
                    <img src={img + "messageSent.png"} alt="Quote" />
                  </div>
                </div>
              </div> { /*<!-- row --> */}
            </div> { /*<!-- container --> */}
        </section>
      </>

    )
}

export default MessageSent