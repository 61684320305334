import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import {Link} from 'react-router-dom';

function GalleryPageBanner({PT, GN, Gid, img, cat}) {
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    return (
        <section id="page-banner" className="pt-70 pb-40 bg_cover" data-overlay="8" style={{backgroundImage: `url(${img}galleryBanner.jpg)`}}>
            <div className="container">
                <div className="page-banner-cont">
                    <h2>{cat===1 && 'Alumni '}Gallery</h2>
                    <nav aria-label="breadcrumb">
                        <div className="row">
                            <div className="col-8">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    { PT ? 
                                        PT==='Pictures' ? 
                                            <>
                                                <li className="breadcrumb-item active"><Link to='/gallery'>Gallery</Link></li>
                                                <li className="breadcrumb-item active" ><Link to={`/gallery/view/${Gid}`}>{GN}</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{PT}</li>

                                            </>
                                        : 
                                            <>
                                                <li className="breadcrumb-item active"><Link to='/gallery'>Gallery</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{PT}</li>
                                            </>
                                    :
                                        <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                                    }
                                </ol>
                            </div>
                            <div className="co-4">
                                {(authDecode && authDecode?.user?.role >=3) && 
                                    <ol className="breadcrumb"><li className="breadcrumb-item active"><Link to="/gallery/add"><i className="fa fa-folder"></i>&nbsp;Add Gallery</Link></li></ol>
                                }                         
                            </div>
                        </div>

                    </nav>
                </div>  { /* <!-- page banner cont --> */}
            </div> { /* <!-- container --> */}
        </section>
    )
}

export default GalleryPageBanner