import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import RightSideBar from "../../Components/RightSideBar";
import { Link, useNavigate } from "react-router-dom";
import GalleryPageBanner from "./GalleryPageBanner";
import { useState, useEffect } from "react";
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import Select from 'react-select';

const VIEW_EVENTS_URL = '/galleries/events';
const UPLOAD_FILE_URL = '/singleUpload';
const ADD_GALLERY_URL = '/galleries/add';

function AddGallery({img}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;  
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState('');
  const [category, setCategory] = useState(0);
  const [file, setFile] = useState(null);
  // eslint-disable-next-line
  const [isClearable, setIsClearable] = useState(true);
  // eslint-disable-next-line
  const [isSearchable, setIsSearchable] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_EVENTS_URL).then((response) => {
      isMounted && setEvents(response.data);
    }).catch((error) => {
      //Handle Errors Coming out of this
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);
    
  const handleGallerySubmit = async (e) => {
    e.preventDefault();
    const newGallery = {name, description, category, galleryId: event, archive: 0, userId: authDecode.user.id};
      
    if(file){
      if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
        alert("Wrong file type. Upload a jpg, jpeg or png");
        navigate(0);
      }else if (file.size > 700000) {
        alert("Image file is too large - Upload file less than 700kb");
        navigate(0);
      }else { 
        const formData = new FormData();
        const filename = Date.now() + '--gallery--' + file.name;
          
        formData.append("name", filename);
        formData.append("file", file);
        newGallery.picture = filename;
      
        try{
          await axiosPrivate.post(UPLOAD_FILE_URL, formData);
        }catch(err){
          console.log(err);
        }

        try {
          await axiosPrivate.post(ADD_GALLERY_URL, newGallery).then((response)=>{
            alert('New Event Added');
            navigate('/gallery');
          }).catch(function(error){
            console.log(error.config);
          });    
        }catch (error) {
          console.log(error);  
        }
      }
    } else {
      alert('The new gallery requires an image.')
      navigate(0);
    }
    
  };

  return (<>
    <GalleryPageBanner PT='Add New Gallery' img={img}/>
    <div className="pb-30 gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="thumb">
              {file && (
                <img src={URL.createObjectURL(file)} alt="" />
              )}
            </div>
            <div className="main-form pt-45">
              <form action="" onSubmit={handleGallerySubmit}>
                <div className="singel-form form-group">
                  <label htmlFor="fileInput" className="p-2">
                    { file ? <h5><i className="fa fa-recycle"></i> Change Image</h5> : <h5><i className="fa fa-image" /> Add Image</h5> }</label>
                  <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                </div>
                <div className="section-title form-group">
                  <br /><h5>Name</h5>
                  <input type="text" placeholder="Gallery Name" className="form-control p-4" autoFocus={true} onChange={(e)=>setName(e.target.value)} />
                </div>
                <div className='section-title form-group'>
                  <br /><h5>Category</h5>
                  <select name="category" className='form-control' value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="0">General Photo Gallery</option>
                    <option value="1">Alumni Photo Gallery</option>
                  </select> 
                </div>                
                <div className="section-title form-group">
                  <br /><h5>Event</h5>
                  <Select placeholder="Search for Event" options={events} isSearchable={isSearchable} isClearable={isClearable} onChange={(e) => {setEvent(e)}} />
                </div>
                <div className="section-title form-group">
                  <br /><h5>Description</h5>
                  <textarea placeholder="Gallery description ..." type="text" className="form-control p-4" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                </div>
                <br />
                <button className="main-btn" disabled={!file || !name || !description} type="submit">Submit</button>
                &nbsp;&nbsp;&nbsp;  
                <Link className="main-btn" to='/gallery'>Cancel</Link>  
              </form>
            </div>
          </div>
          <RightSideBar />
        </div>
      </div>
    </div>
    </>)
}

export default AddGallery
