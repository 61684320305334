import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import RightSideBar from "../../Components/RightSideBar"
import {Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NewsPageBanner from "./NewsPageBanner";

const VIEW_NEWS_URL = '/news';

function Stories({img, cat}) {
    const axiosPrivate = useAxiosPrivate();
    const [topStory, setTopStory] = useState({});
    const [allStories, setAllStories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSet, setPageSet] = useState(1);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();  
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_NEWS_URL + '?page=' + page + '&cat=' + cat).then((response) => {
            if(isMounted){
                setTopStory(response.data.topStory);
                setAllStories(response.data.allStories);
                setTotalPages(response.data.totalPages);
            } 
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [page]);

    let pages = [];
    const pageSets = Math.ceil(totalPages/5);
  
    for(let i = pageSet; i<=totalPages; i++){
        pages.push(i);
    }

    return (
        <>
            <NewsPageBanner img={img} cat={cat}/>
            <section id="blog-page" className="pb-30 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            { topStory?.id ?
                                <> 
                                    <div className="singel-blog mt-30" onClick = { ()=> navigate(`/news/view/${topStory.id}`)} style={{cursor: 'pointer'}}>
                                        <div className='blog-thum'>
                                            <img src={img + topStory.picture} alt="" />
                                        </div>
                                        <div className="blog-cont">
                                            <Link to={`/news/view/${topStory.id}`}><h4>{topStory.title}</h4></Link>
                                            <ul>
                                                <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(topStory.createdAt).toDateString()}</Link></li>
                                            </ul>
                                            <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: topStory.detail}} />
                                        </div>
                                    </div>                            
                                    <div className="row">
                                        { allStories.map((value)=>{
                                            return (
                                                <div key={value.id} className='mt-30 col-md-6'>
                                                    <div className='singel-blog' onClick={() => navigate(`/news/view/${value.id}`)} style={{cursor: 'pointer'}}>
                                                        <div className='blog-thum'>
                                                            <img src={img + value.picture} alt="" />
                                                        </div>
                                                        <div className="blog-cont">
                                                            <Link to={`/news/view/${value.id}`}><h4>{value.title}</h4></Link>
                                                            <ul>
                                                                <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(value.createdAt).toDateString()}</Link></li>
                                                            </ul>
                                                            <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: value.detail}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                    <div className="col-lg-12">
                                        <nav className="courses-pagination mt-50">
                                            <ul className="pagination justify-content-center">
                                            {pageSet!==1 &&
                                                <li className="page-item">
                                                <Link to="#previous" aria-label="Previous" onClick={(e)=>{setPageSet(pageSet-5)}}>
                                                    <i className="fa fa-angle-left"></i>
                                                </Link>
                                                </li>                                                
                                            }
                                            {
                                                pages.slice(0,5).map((pageNum, index) => {return <li className="page-item" key={index}><Link className="active" to={`#page=${pageNum}`} onClick={(e)=>setPage(pageNum)}>{pageNum}</Link></li>})
                                            }
                                            {pageSet<pageSets && 
                                                <li className="page-item">
                                                <Link to="#next" aria-label="Next" onClick={(e)=>{setPageSet(pageSet+5)}}>
                                                    <i className="fa fa-angle-right"></i>
                                                </Link>
                                                </li>                                                    
                                            }
                                            </ul>
                                        </nav>  { /*<!-- courses pagination --> */}
                                    </div>
                                </>
                            :
                                <div className="singel-blog">
                                    <div className='contact-from mt-30 mb-300'>
                                        <div className="section-title">
                                            <h5>news</h5>
                                            <h2>News</h2>
                                        </div> { /* <!-- section title --> */ }
                                        <div className='blog-details pt-45 mb-200'>
                                            <p>There are no news stories at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <RightSideBar img={img}/>
                    </div> { /* <!-- row --> */ }
                </div> { /* <!-- container --> */ }
            </section>
        </>
    )
}

export default Stories