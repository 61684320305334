import RequireAuth from '../Components/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import ViewGalleries from './Gallery/Galleries';
import ViewGallery from './Gallery/ViewGallery';
import AddPictures from './Gallery/AddPictures';
import EditPicture from './Gallery/EditPicture';
import AddGallery from './Gallery/AddGallery';

function Gallery({img, cat}) {
  return (
    <Routes>
      <Route exact path='/' element={<ViewGalleries img={img} cat={cat}/>} />
      <Route exact path='/view/:id' element={<ViewGallery img={img} cat={cat}/>} />
      <Route element={<RequireAuth allowedRoles={[3, 4, 5]} />}>
        <Route exact path='/add' element={<AddGallery img={img} cat={cat}/>} />
        <Route exact path='/addpics/:id' element={<AddPictures img={img} cat={cat}/>} />
        <Route exact path='/edit/:id' element={<EditPicture img={img} cat={cat}/>} />
      </Route>
    </Routes>
  )
}

export default Gallery