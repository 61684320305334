//import EventsList from './EventsList';
import GalleryHighlights from './GalleryHighlights';
import PagesIntro from './PagesIntro';

function About({img}) {

  return (
    <>
      <section>
        <div className="container pb-30">
          <div className="row">
            <PagesIntro/>
            {/* <EventsList/> */}
            <GalleryHighlights img={img} />
          </div> 
        </div>
        {/* <div className="about-bg">
          <img src={`${img}about/IMG_7827.jpg`} alt="About" />
        </div> */}
      </section>
    </>
  )
}

export default About