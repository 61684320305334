/* eslint-disable jsx-a11y/anchor-has-content */
import RightSideBar from '../../Components/RightSideBar';
import EventsPageBanner from './EventsPageBanner';
import UpComingEvents from './UpComingEvents';
import PastEvents from './PastEvents';

function ViewEvents({img, cat}) {

    return (
        <>
            <EventsPageBanner img={img} cat={cat}/>
            <section id="blog-page" className="pb-30 gray-bg">
                <div className='container'>
                    <div className="row">
                        <div className='col-lg-8'>
                            <UpComingEvents img={img} cat={cat}/>
                            <PastEvents img={img} cat={cat}/>
                        </div>
                        <RightSideBar img={img}/>
                    </div> { /*<!-- row --> */}
                </div>
            </section>
        </>
    )
}

export default ViewEvents
