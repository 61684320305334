import {Link} from 'react-router-dom';

function PagesBanner({img, title}) {
    let banners = ["pagesBanner1.jpg", "pagesBanner2.jpg", "pagesBanner3.jpg", "pagesBanner4.jpg", "pagesBanner5.jpg", "pagesBanner6.jpg", "pagesBanner7.jpg"];
    const pageBanner = banners[(Math.floor(Math.random() * banners.length))]

    return (
        <section id="page-banner" className="pt-80 pb-50 bg_cover" data-overlay="8" style={{backgroundImage: `url(${img + 'banners/' + pageBanner})`}}>
        <div className="container">
            <div className="page-banner-cont">
                <h2>{title}</h2>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{title}</li>
                    </ol>
                </nav>
            </div>  { /* <!-- page banner cont --> */}
        </div>
    </section>
    )
}

export default PagesBanner