import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import {Link} from 'react-router-dom';

function ArticlePageBanner({PT, img, cat}) {
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    return (
        <section id="page-banner" className="pt-70 pb-40 bg_cover" data-overlay="8" style={{backgroundImage: `url(${img}blogBanner.jpg)`}}>
            <div className="container">
                <div className="page-banner-cont">
                    <h2>{cat===1 ? 'Alumni Testimonials' : 'Blog'}</h2>
                    <nav aria-label="breadcrumb">
                        <div className='row'>
                            <div className='col-8'>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    {PT ? 
                                        <>
                                            <li className="breadcrumb-item active"><Link to='/blog'>Blog</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{PT}</li>
                                        </>
                                    :
                                        <li className="breadcrumb-item active"  aria-current="page">Blog</li>
                                    }
                                </ol>
                            </div>
                            <div className='col-4'>
                                { PT!=='New Blog Post' ?
                                    (authDecode && authDecode?.user?.role >=3) && 
                                        <ol className="breadcrumb"><li className="breadcrumb-item active"><Link to="/blog/add"><i className="fa fa-file"></i>&nbsp;Add Post</Link></li></ol>
                                : <></>}
                            </div>
                        </div>
                    </nav>
                </div>  { /* <!-- page banner cont --> */}
            </div> { /* <!-- container --> */}
        </section>
    )
}

export default ArticlePageBanner