import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { Link, useNavigate } from "react-router-dom";
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { useState } from "react";

//Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const UPLOAD_FILE_URL = '/singleUpload';
const ADD_NEWS_URL = '/news/add';

function NewStoryForm() {
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const [category, setCategory] = useState(0);
    const [detail, setDetail] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [file, setFile] = useState(null);
    let navigate = useNavigate();

    const onDetail = (value) => {
        setDetail(value);
    } 
    
    const storySubmit = async (e) => {
        e.preventDefault();
        let newStory = {};
        if(date){
            newStory = { title, detail, category, userId: authDecode.user.id, createdAt: date};
        }else {
            newStory = { title, detail, category, userId: authDecode.user.id, };
        }

        if(file){
            if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                alert("Wrong file type. Upload a jpg, jpeg or png");
                navigate(0);
            }else if (file.size > 700000) {
                alert("Image file is too large - Upload file less than 700kb");
                navigate(0);
            }else { 
                const formData = new FormData();
                const filename = Date.now() + file.name;
                
                formData.append("name", filename);
                formData.append("file", file);
                newStory.picture = filename;

                try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);
                }catch(err){
                    console.log(err);
                }
                
                try {
                    await axiosPrivate.post(ADD_NEWS_URL, newStory).then((response)=>{
                        alert('New Story Added');
                        navigate('/news');
                    }).catch(function(error){
                        console.log(error.config);
                    })  
                }catch (error) {
                    console.log(error);  
                }
            }
        } else {
            alert('The news story requires an image.')
            navigate(0);
        }

    };

    return (
        <div className="col-lg-8">
            <div className="blog-details mt-30">
                <div className="thum">
                    {file && (
                        <img className="img-fluid" src={URL.createObjectURL(file)} alt="" />
                    )}
                </div>
                <div className='main-form pt-45'>
                    <form onSubmit={storySubmit}>
                        <div className="singel-form form-group">
                            <label htmlFor="fileInput">
                                { file ? <h5><i className="fa fa-recycle"></i> Change Image</h5> : <h5><i className="fa fa-image"></i> Add Image</h5>}  
                            </label> 
                            <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                        </div>
                        <div className="row form-group section-title">
                            <div className="col-lg-8">
                                <br /><h5>Title</h5>
                                <input type="text" placeholder="News Story Title" className="form-control p-4" required="required" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />
                            </div>
                            <div className='section-title form-group'>
                                <br /><h5>Category</h5>
                                <select name="category" className='form-control' value={category} onChange={(e) => setCategory(e.target.value)}>
                                <option value="0">General News Story</option>
                                <option value="1">Alumni News</option>
                                </select> 
                            </div>                
                            <div className="col-lg-4">
                                <br /><h5>Date</h5>
                                <input value={date} type='date' className="p-4 form-control" autoFocus={true} onChange={(e)=>setDate(e.target.value)} />

                            </div>
                        </div>
                        <div className="section-title form-group">
                            <br /><h5>Story Detail</h5>
                            <ReactQuill theme="snow" rows="10" value={detail} onChange={onDetail} placeholder={"News story details..."} />
                        </div>
                        <br />
                        <button className="main-btn" disabled={!file || !title || !detail} type="submit">Submit</button>
                        &nbsp;&nbsp;&nbsp;  
                        <Link className="main-btn" to='/news'>Cancel</Link>  
                    </form>
                </div>
            </div>
        </div>
    );
}

export default NewStoryForm