/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const VIEW_IMAGES_URL = '/galleries/home';

function GalleryHighlights({img}) {
    const axiosPrivate = useAxiosPrivate();
    const [imageList, setImageList] = useState([]);  
    
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_IMAGES_URL).then((response)=>{
        isMounted && setImageList(response.data);
      }).catch((error) => {
        console.log('Error', error.message);
      });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, []);


  return (
    <div className='col-lg-6 mt-50'>
        {/* <div className="row"> */}
            {imageList.length>0 && imageList.map((image)=>{
                return(
                    <div className="mb-5" key={image.id}>
                        <Link to={`/gallery/view/${image.galleryId}`}><img className="img-fluid w-100 h-100" src={img + image.picture} style={{objectFit: 'cover'}} alt="" /></Link>
                    </div>    
                )
            })}
        {/* </div> */}
    </div>
  )
}

export default GalleryHighlights