/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const VIEW_EVENTS_URL = '/events/home';

function Events({img}) {
  const axiosPrivate = useAxiosPrivate();
  const [eventList, setEventList] = useState([]); 
  const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];

  let images = [
  "facilities/facilities01.jpg", 
  "facilities/facilities02.jpg", 
  "facilities/facilities03.jpg", 
  "facilities/facilities04.jpg", 
  "facilities/facilities05.jpg", 
  "facilities/facilities06.jpg", 
  "facilities/facilities07.jpg", 
  "facilities/facilities08.jpg", 
  "facilities/facilities09.jpg", 
  "facilities/facilities10.jpg", 
  "facilities/facilities11.jpg", 
  "facilities/facilities12.jpg", 
  "facilities/facilities13.jpg", 
  "facilities/facilities14.jpg", 
  "facilities/facilities15.jpg", 
  "facilities/facilities16.jpg",
  "hero/hero01.jpg",
  "hero/hero02.jpg",
  "hero/hero03.jpg",
  "hero/hero04.jpg",
  "hero/hero05.jpg",
  "hero/hero06.jpg",
  "hero/hero07.jpg",
  "hero/hero08.jpg",
  "hero/hero09.jpg",
  "hero/hero10.jpg"
  ];

  const image = images[(Math.floor(Math.random() * images.length))]
  
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_EVENTS_URL).then((response)=>{
      isMounted && setEventList(response.data);
    }).catch((error) => {
      console.log('Error', error.message);
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);

  return (
    <section id="video-feature" className="bg_cover mb-30" style={{backgroundImage: `url(${img + image})`}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 order-last order-lg-first">
            <div className="feature pt-50">
              <div className="feature-title">
                <h3>Upcoming Events</h3>
              </div>
              {eventList.length>0 ? 
                <ul>
                  { eventList.map((calEvent) => {
                    return (
                      <li key={calEvent.id} className="singel-feature">
                        <div className="singel-feature">
                          <div className='cont'>
                            <div className="row">
                              <div className='col-3 pt-1'>
                                <p className='px-3'>{ month[new Date(calEvent.date).getMonth()]}</p>
                                {(new Date(calEvent.date).getDate())>9 ? 
                                  <p className='date-h2'> {new Date(calEvent.date).getDate()}</p>
                                :
                                  <p className='date-h2'> 0{new Date(calEvent.date).getDate()}</p>
                                }
                                <p className='px-2'>{ new Date(calEvent.date).getFullYear()}</p>
                              </div>
                              <div className="col-9 pt-1">
                                <Link to={`/events/view/${calEvent.id}`}><h4>{calEvent.title}</h4></Link>
                              </div>
                            </div>
                        </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              :
                <div className='blog-details pt-45'>
                    <p>There are no scheduled events at the moment.</p> 
                </div>
              }
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1 order-first order-lg-last">
              
          </div>
        </div> 
      </div> 
      <div className="feature-bg-left" /> 
    </section>
  )
}

export default Events