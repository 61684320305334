/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate, useParams} from 'react-router-dom';
import EventsPageBanner from './EventsPageBanner';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import RightSideBar from '../../Components/RightSideBar';

const VIEW_EVENTS_URL = '/events/view/';
const DELETE_EVENT_URL = '/events/delete/';
const EDIT_EVENT_URL = '/events/edit/';

function ViewEvent({img}) {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;

    const [updateMode, setUpdateMode] = useState(false);
    const [calEvent, setCalEvent] = useState({}); 
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [location, setLocation] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [category, setCategory] = useState(0);
    const [gallery, setGallery] = useState({});
    const navigate = useNavigate();
    let {id} = useParams();
    
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_EVENTS_URL + id).then((response)=>{
        if(isMounted){
            setCalEvent(response.data.calEvent)
            setTitle(response.data.calEvent.title);
            setLocation(response.data.calEvent.location);
            setEventDate(response.data.calEvent.date.split('T')[0]);
            setDescription(response.data.calEvent.description);
            setCategory(response.data.calEvent.category);
            setGallery(response.data.gallery);

          }
      }).catch((error) => {
        //Handle Errors Coming out of this
    });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, []);
  
    const updateCalEvent = async(e) => {
        e.preventDefault();
        const date = eventDate;
        const updatedEvent = {title, date, location, description, category, UserId: authDecode.user.id, };
  
        try {
          axiosPrivate.put(EDIT_EVENT_URL + calEvent.id, updatedEvent).then(()=>{
            alert('Calendar Event Edited');
            navigate('/events');
          }).catch(function(error){
              console.log(error.config);
          });   
        }catch (error) {
            console.log(error);  
        }
    }

    const deleteCalEvent = async(id) => {
      try {
        axiosPrivate.delete(DELETE_EVENT_URL + id).then(()=>{
          alert('Calendar Event Deleted');
          navigate('/events');
        });               
      } catch (error) {       
      }
    };

    return (
        <>
            <EventsPageBanner PT={calEvent.title} img={img}/>
            <section id="event-singel" className="pb-30 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className='col-lg-8 mt-30 mb-60'>
                            <div className="events-area">
                                { updateMode ? 
                                    <div className='main-form'>
                                        <form data-toggle="validator">
                                            <div className="form-group section-title">
                                                <br/><h5>Event Name</h5>
                                                <input type='text' value={title} className="form-control p-2" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />         
                                                <div className="help-block with-errors"></div>
                                            </div> {/* Event Title */}
                                            <div className='section-title form-group'>
                                                <br /><h5>Category</h5>
                                                <select name="category" className='form-control' value={category} onChange={(e) => setCategory(e.target.value)}>
                                                <option value="0">General Calendar Event</option>
                                                <option value="1">Alumni Event</option>
                                                </select> 
                                            </div> {/** Event Category */}
                                            <div className="events-left">
                                                <div className="events-right pt-10">
                                                    <div className="form-group section-title">
                                                        <br/><h5>Date</h5>
                                                        <input value={eventDate} type='date' className="p-2 form-control" autoFocus={true} onChange={(e)=>setEventDate(e.target.value)} />
                                                        <div className="help-block with-errors"></div>
                                                    </div> {/* Event Date*/}               
                                                    <div className="form-group section-title">
                                                        <br /><h5>Location</h5>
                                                        <input value={location} type='text' placeholder='' className="form-control p-2" autoFocus={true} onChange={(e)=>setLocation(e.target.value)} />
                                                        <div className="help-block with-errors"></div>
                                                    </div>{/* Location */}
                                                    <div className="form-group section-title">
                                                        <br /><h5>Description</h5>
                                                        <textarea value={description} type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                                                        <div className="help-block with-errors"></div>
                                                    </div>{/* Description */}
                                                </div> { /* <!-- events right --> */}
                                            
                                            </div>{/* Event Details */}
                                            <br />
                                            <button className="main-btn" disabled={!title || !eventDate || !location || !description} type="submit" onClick={(e)=>updateCalEvent(e)}>Submit</button>
                                            &nbsp;&nbsp;&nbsp;  
                                            <button className="main-btn" onClick={(e)=>setUpdateMode(false)}>Cancel</button>
                                        </form>
                                    </div>
                                :                        
                                    <div className="events-left">
                                        <h3>{calEvent.title}</h3>
                                        <div className="events-address mt-30">
                                            <div className='row'>
                                                {(authDecode && (authDecode?.user?.id === calEvent.UserId || authDecode?.user?.role >=4)) ? 
                                                    <>
                                                        <div className="col-lg-4 mb-30">
                                                            <div className='pt-2'>
                                                                <span className='icon'><i className="fa fa-calendar"></i></span>
                                                            </div>
                                                            <div className="cont">
                                                                <span>{new Date(calEvent.date).toDateString()}</span>
                                                            </div>
                                                        </div> { /* <!-- event Date --> */}
                                                        <div className="col-lg-3">
                                                            <div className="pt-2">
                                                                <span className='icon'><i className="fa fa-map-marker"></i></span>
                                                            </div>
                                                            <div className="cont">
                                                                <span>{calEvent.location}</span>
                                                            </div>
                                                        </div> { /* <!-- Event Location --> */}
                                                        <div className="singel-address col-lg-2"  onClick={() => setUpdateMode(true)} style={{cursor: 'pointer'}}>
                                                            <div className="pt-2">
                                                                <span className='icon'><i className="fa fa-edit" aria-hidden="true"/></span>
                                                            </div>
                                                            <div className="cont">
                                                                <span>Edit</span>
                                                            </div>
                                                        </div> { /* <!-- Edit Event --> */}
                                                        <div className="singel-address col-lg-3" onClick={() => deleteCalEvent(calEvent.id)} style={{cursor: 'pointer'}}>
                                                            <div className="pt-2">
                                                                <span className='icon'><i className="fa fa-trash" aria-hidden="true" /></span>
                                                            </div>
                                                            <div className="cont">
                                                                <span>Delete</span>
                                                            </div>
                                                        </div> { /* <!-- Delete Event --> */}
                                                    </>
                                                    :
                                                    <>
                                                        <div className="col-lg-6 mb-30">
                                                            <div className='pt-2'>
                                                                <span className='icon'><i className="fa fa-calendar"></i></span>
                                                            </div>
                                                            <div className="cont">
                                                                <span>{new Date(calEvent.date).toDateString()}</span>
                                                            </div>
                                                        </div> { /* <!-- singel address --> */}
                                                        <div className="col-lg-6">
                                                            <div className="pt-2">
                                                                <span className='icon'><i className="fa fa-map-marker"></i></span>
                                                            </div>
                                                            <div className="cont">
                                                                <span>{calEvent.location}</span>
                                                            </div>
                                                        </div> { /* <!-- singel address --> */}
                                                    </>
                                                }
                                            </div> { /* <!-- event icons --> */}
                                            <hr />
                                            {calEvent.galleryId>0 && 
                                                <div className="singel-news news-list">
                                                    <h5>Event in Pictures</h5>
                                                    <div className="row" onClick = {(e)=> navigate(`/gallery/view/${gallery.id}`)} style={{cursor: 'pointer'}}>
                                                        <div className="col-sm-4">
                                                            <div className="news-thum mb-5">
                                                                <img src={img + gallery.picture} alt="News" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8 mt-5">
                                                            <div className="news-cont">
                                                                <Link to={`/gallery/view/${gallery.id}`}><p>{gallery.name}</p></Link>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>                                            
                                            }
                                        </div> { /* <!-- events address --> */}
                                        <p dangerouslySetInnerHTML={{ __html: calEvent.description}} />
                                    </div> //{* <!-- events left --> */}
                                }
                            </div> { /* <!-- events-area --> */}
                        </div>
                        <RightSideBar img={img}/>
                    </div> { /* <!-- events-area --> */}
                </div> { /* <!-- container --> */}
            </section>
        </>    
    )
}

export default ViewEvent