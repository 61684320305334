import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

const VIEW_PAGE_URL = '/pages/view/about';

function PagesIntro() {
  const [page, setPage] = useState([]);  

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    axiosPrivate.get(VIEW_PAGE_URL).then((response)=>{
      isMounted && setPage(response.data.page);
    }).catch((error) => {
      console.log('Error', error.message);
    });
    return () => { isMounted = false; controller.abort();  }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-6">
        { page && <>
            <div className="section-title mt-50">
            <h5>{page.tag}</h5>
            <h2>{page.title}</h2>
            </div>
            <div className="about-cont">
              <p className="about-cont paraLimit2" dangerouslySetInnerHTML={{ __html: page.pageText}} style={{whiteSpace: "pre-line"}} />
               ... <Link to="/about"> read more</Link>
            </div>
          </>}
    </div>

  )
}

export default PagesIntro