function Facilities({img}) {

  let images = ["facilities01.jpg", "facilities02.jpg", "facilities03.jpg", "facilities04.jpg", "facilities05.jpg", "facilities06.jpg", "facilities07.jpg", "facilities08.jpg", "facilities09.jpg", "facilities10.jpg", "facilities11.jpg", "facilities12.jpg", "facilities13.jpg", "facilities14.jpg", "facilities15.jpg", "facilities16.jpg"];

  const image = images[(Math.floor(Math.random() * images.length))]
  return (
    <section id="video-feature" className="bg_cover mb-30" style={{backgroundImage: `url(${img + 'facilities/' + image})`}}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-last order-lg-first">
            
          </div>
          <div className="col-lg-5 offset-lg-1 order-first order-lg-last pb-20">
            <div className="feature pt-50">
              <div className="feature-title">
                <h3>Our Facilities</h3>
              </div>
              <ul>
              <li>
                  <div className="singel-feature">
                    <div className="icon">
                      <img src={`${img}all-icon/f-3.png`} alt="icon" />
                    </div>
                    <div className="cont">
                      <h4>Books &amp; Library</h4>
                      <p>... where pages whisper stories and shelves hold the keys to endless adventures.</p>
                    </div>
                  </div> 
                </li>
                <li>
                  <div className="singel-feature">
                    <div className="icon">
                      <img src={`${img}all-icon/sports.png`} alt="icon"/>
                    </div>
                    <div className="cont">
                      <h4>Sports</h4>
                      <p>... fueling passion and fostering excellence, our facilities are dedicated to cultivating athletic prowess and sportsmanship in every student.</p>
                    </div>
                  </div> 
                </li>
                <li>
                  <div className="singel-feature">
                    <div className="icon">
                      <img src={`${img}all-icon/computer.png`} alt="icon" />
                    </div>
                    <div className="cont">
                      <h4>Information Technology</h4>
                      <p>... our IT facilities ensures a dynamic and secure digital environment for learning and innovation.</p>
                    </div>
                  </div> 
                </li>
              </ul>
            </div>
          </div>
        </div> 
      </div> 
      <div className="feature-bg" /> 
    </section>
  )
}

export default Facilities