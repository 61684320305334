import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate } from "react-router-dom";
import EventsPageBanner from './EventsPageBanner';
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';
import { useState } from "react";

const ADD_EVENT_URL = '/events/add';

function AddEvent({img}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const [description, setDescription] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState(0);
  const [title, setTitle] = useState('');
  let navigate = useNavigate();
  
  const eventSubmit = async (e) => {
    e.preventDefault();

    const date = eventDate;
    const newEvent = {title, date, location, description, category, author: authDecode.user.id, };
     
    try {
      await axiosPrivate.post(ADD_EVENT_URL, newEvent).then((response)=>{
        alert('New Event Added');
        navigate('/events');
      }).catch(function(error){
        console.log(error.config);
      });  
    }catch (error) {
      console.log(error);  
    }
  };

  return (
    <>
      <EventsPageBanner PT={'Add Event'} img={img}/>
      <section id="event-singel" className="pt-30 pb-30 gray-bg">
        <div className="container">
          <div className='events-area'>
            <div className="row">
              <div className='main-form col-lg-6'>
                <form data-toggle="validator">
                  <div className="form-group section-title">
                    <br/><h5>Event Name</h5>
                    <input type='text' value={title} className="form-control p-2" autoFocus={true} onChange={(e)=>setTitle(e.target.value)} />         
                    <div className="help-block with-errors"></div>
                  </div> {/* Event Title */}
                  <div className='section-title form-group'>
                    <br /><h5>Category</h5>
                    <select name="category" className='form-control' value={category} onChange={(e) => setCategory(e.target.value)}>
                      <option value="0">General Calendar Event</option>
                      <option value="1">Alumni Event</option>
                    </select> 
                  </div> {/** Event Category */}               
                  <div className="events-left">
                    <div className="events-right pt-10">
                      <div className="form-group section-title">
                        <br/><h5>Date</h5>
                        <input type='date' className="p-2 form-control" autoFocus={true} onChange={(e)=>setEventDate(e.target.value)} />
                        <div className="help-block with-errors"></div>
                      </div> {/* Event Date*/}               
                      <div className="form-group section-title">
                        <br /><h5>Location</h5>
                        <input placeholder='Event Location' type='text' className="form-control p-2" autoFocus={true} onChange={(e)=>setLocation(e.target.value)} />
                        <div className="help-block with-errors"></div>
                      </div>{/* Location */}
                      <div className="form-group section-title">
                        <br /><h5>Description</h5>
                        <textarea placeholder='Event description' type='text' className="form-control p-2" rows="4" onChange={(e)=>setDescription(e.target.value)} />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div> { /* <!-- events right --> */}
                  </div>{/* Rightside */}
                  <br />
                  <button className="main-btn" disabled={!title || !eventDate} type="submit" onClick={(e)=>eventSubmit(e)}>Submit</button>
                  &nbsp;&nbsp;&nbsp;  
                  <Link className="main-btn" to='/events'>Cancel</Link>
                </form>
              </div>
              <div className='col-lg-6'></div>
            </div>
          </div>
        </div>
      </section>    
    </>
  )
}

export default AddEvent
