import RightSideBar from "../../Components/RightSideBar";
import ArticlePageBanner from "./ArticlePageBanner";
import NewArticleForm from "./NewArticleForm";


function AddArticle({img}) {
  return (
    <>
      <ArticlePageBanner PT='New Blog Post' img={img}/>
      <section className="pt-90 pb-120 gray-bg">
        <div className="container">
          <div className="row">
            <NewArticleForm />            
            <RightSideBar img={img}/>
          </div>
        </div>
      </section>

    </>
  );
}

export default AddArticle