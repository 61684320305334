import { AuthProvider } from './Assets/AuthProvider';
import { Route, Routes } from 'react-router-dom';

//Components
import PersistLogin from './Components/PersistLogin';
import RequireAuth from './Components/RequireAuth';
import ScrollToTop from './Components/ScrollToTop';
import Footer from './Components/Footer';
import Layout from './Components/Layout';
import Navbar from './Components/Navbar';

// Modules
import Articles from './Modules/Articles';
import Profiles from './Modules/Profiles';
import Gallery from './Modules/Gallery';
import Events from './Modules/Events';
import Alumni from './Modules/Alumni';
import Admin from './Modules/Admin';
import User from './Modules/User';
import News from './Modules/News';
import Home from './Modules/Home';

//Single Pages
import Contact from './Pages/Contact';
import Apply from './Pages/Apply';
import Login from './Pages/Login';
import Pages from './Pages/Pages';
import MessageSent from './Pages/MessageSent';

function App() {

  let IMAGE_URL = 'http://localhost:5006';
  if(process.env.NODE_ENV === 'production'){ IMAGE_URL = 'https://www.kja.ug/app'; }
  
  const img = `${IMAGE_URL}/images/`;

  return (
    <AuthProvider>
      <div className="App">
        <ScrollToTop />
        <Navbar img={img}/>
        <Routes>
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Layout />}>
              <Route exact path='/' element={<Home img={img}/>}/>
              <Route exact path='/profiles/*' element={<Profiles img={img}/>} />
              <Route exact path='/gallery/*' element={<Gallery img={img} cat={0}/>} />
              <Route exact path='/blog/*' element={<Articles img={img} cat={0}/>} />
              <Route exact path='/events/*' element={<Events img={img} cat={0}/>} />
              <Route exact path='/sent/*' element={<MessageSent img={img}/>} />
              <Route exact path='/alumni/*' element={<Alumni img={img}/>} />
              <Route exact path='/contact' element={<Contact img={img}/>} />
              <Route exact path='/apply/*' element={<Apply img={img}/>} />
              <Route exact path='/news/*' element={<News img={img} cat={0}/>} />
              <Route exact path='/login' element={<Login img={img}/>} />
              <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4, 5]} />}>
                <Route exact path='/user/*' element={<User img={img}  />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={[4, 5]} />}>
                <Route exact path='/admin/*' element={<Admin img={img}/>} />
              </Route>
              <Route exact path='/*' element={<Pages img={img} />} />
            </Route>
          </Route>
        </Routes>
        <Footer img={img}/>
      </div>
    </AuthProvider>
  );
}

export default App;