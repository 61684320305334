import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react';

const CONTACT_URL = '/mail/apply';

function Apply({img}) {
    const axiosPrivate = useAxiosPrivate();
    const [pName, setPName] = useState('');
    const [cName, setCName] = useState('');
    const [bDate, setBDate] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const contactMsg = { pName, email, phone, cName, bDate, message};

        try {
            await axiosPrivate.post(CONTACT_URL, contactMsg).then((response)=>{
                alert('Form Successfully Submitted');
                navigate('/sent');
            });   
        }catch (error) {
            console.log(error);  
        }

    }

  return (
    <>
        <section id="page-banner" className="pt-80 pb-50 bg_cover" data-overlay="8" style={{backgroundImage: `url(${img}page-banner-6.jpg)`}}>
            <div className="container">
                <div className="page-banner-cont">
                    <h2>Apply to Enroll</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Apply</li>
                        </ol>
                    </nav>
                </div>  { /* <!-- page banner cont --> */}
            </div> { /* <!-- container --> */}
        </section>
        <section id="contact-page" className="pb-30 gray-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="contact-from mt-30">
                            <div className="section-title">
                                <h5>apply</h5>
                                <h2>Enroll with us</h2>
                            </div> { /* <!-- section title --> */ }
                            <div className="main-form pt-45">
                                <form id="contact-form" onSubmit={handleSubmit} data-toggle="validator">
                                    <div className="singel-form form-group section-title">
                                        <h5>Parents's Details</h5>
                                        <input name="pname" type="text" placeholder="Parent's name" data-error="Name is required." required="required" onChange={(e)=>setPName(e.target.value)} />
                                        <div className="help-block with-errors"></div>
                                    </div> { /* <!-- singel form --> */ }
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="singel-form form-group">
                                                <input name="email" type="email" placeholder="Email" data-error="Valid email is required." required="required" onChange={(e)=>setEmail(e.target.value)} />
                                                <div className="help-block with-errors"></div>
                                            </div> { /* <!-- singel form --> */ }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="singel-form form-group">
                                                <input name="phone" type="text" placeholder="Parent's Phone" data-error="Phone is required." required="required" onChange={(e)=>setPhone(e.target.value)} />
                                                <div className="help-block with-errors"></div>
                                            </div> { /* <!-- singel form --> */ }
                                        </div>
                                        <div className="col-md-12">
                                            <div className="singel-form form-group section-title">
                                                <br /><h5>Child's Details</h5>
                                                <input name="cname" type="text" placeholder="Child's name" data-error="Subject is required." required="required" onChange={(e)=>setCName(e.target.value)} />
                                                <div className="help-block with-errors"></div>
                                            </div> { /* <!-- singel form --> */ } 
                                        </div>
                                        <div className="col-md-12">
                                            <div className="singel-form section-title form-group">
                                                Birthdate
                                                <input type='date' className="p-2 form-control" name="bDate" autoFocus={true}  onChange={(e)=>setBDate(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="singel-form form-group section-title">
                                                <br />
                                                <h5>Additional Details</h5>
                                                <textarea name="messege" placeholder="Messege" data-error="Please, leave us a message." required="required" onChange={(e)=>setMessage(e.target.value)}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div> { /* <!-- singel form --> */ }
                                        </div>
                                        <p className="form-message"></p>
                                        <div className="col-md-12">
                                            <div className="singel-form">
                                                <button type="submit" className="main-btn">Send</button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link className="main-btn" to='/'>Cancel</Link>
                                            </div> { /* <!-- singel form --> */ }
                                        </div> 
                                    </div> { /* <!-- row --> */ }
                                </form>
                            </div> { /* <!-- main form --> */ }
                        </div> { /* <!--  contact from --> */ }
                    </div>
                    <div className="col-lg-5">
                        <div className="contact-address mt-30">
                            <ul>
                                <li>
                                    <div className="singel-address">
                                        <div className="icon">
                                            <i className="fa fa-home"></i>
                                        </div>
                                        <div className="cont">
                                            <p>Yusuf Lule Road, Kampala, Uganda</p>
                                        </div>
                                    </div> { /* <!-- singel address --> */ }
                                </li>
                                <li>
                                    <div className="singel-address">
                                        <div className="icon">
                                            <i className="fa fa-phone"></i>
                                        </div>
                                        <div className="cont">
                                            <p>+256 392 700 370</p>
                                        </div>
                                    </div> { /* <!-- singel address --> */ }
                                </li>
                                <li>
                                    <div className="singel-address">
                                        <div className="icon">
                                            <i className="fa fa-envelope-o"></i>
                                        </div>
                                        <div className="cont">
                                            <Link to='#email'>info@kja.ug</Link>
                                        </div>
                                    </div> { /* <!-- singel address --> */ }
                                </li>
                            </ul>
                        </div> { /* <!-- contact address --> */ }
                        <div className="map mt-30">
                            <div id="contact-map"></div>
                        </div> { /* <!-- map --> */ }
                    </div>
                </div> { /* <!-- row --> */ }
            </div> { /* <!-- container --> */ }
        </section>
    </>
  )
}

export default Apply